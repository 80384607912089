import React, { useState } from "react";
import "../../style/MagaxineALL/index.scss";
import { useAtom } from "jotai";
import { MagazineData } from "../../Jotai/index";
import { NavLink } from "react-router-dom";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";

const MagazineAll = (props) => {
  const [magazineSearch, setmagazineSearch] = useState("");
  const [magazineType, setmagazineType] = useState("all");
  const [Magazinedata, setMagazineData] = useAtom(MagazineData);

  const SelectmagazineType = (name) => {
    setmagazineType(name);
  };

  const handleInputChange = (event) => {
    setmagazineSearch(event.target.value);
  };

  return (
    <>

      <div className="MagazineAll">
        <ScrollToTop/>

        <div className="searchBar">
          <input
            type="text"
            id="searchBar"
            name="searchBar"
            placeholder="Search Magazine"
            className="inputSearchbar"
            onChange={handleInputChange}
          />
        </div>

        <div className="btnSearchField">
          <div
            onClick={() => {
              SelectmagazineType("all");
            }}
            className={
              magazineType === "all"
                ? "btnSearchField_tag btnSearchField_tag_active"
                : "btnSearchField_tag"
            }
          >
            All
          </div>
          <div
            onClick={() => {
              SelectmagazineType("onlinearticles");
            }}
            className={
              magazineType === "onlinearticles"
                ? "btnSearchField_tag btnSearchField_tag_active"
                : "btnSearchField_tag"
            }
          >
            Online Articles{" "}
          </div>
          <div
            onClick={() => {
              SelectmagazineType("arts");
            }}
            className={
              magazineType === "arts"
                ? "btnSearchField_tag btnSearchField_tag_active"
                : "btnSearchField_tag"
            }
          >
            Arts
          </div>
          <div
            onClick={() => {
              SelectmagazineType("culture");
            }}
            className={
              magazineType === "culture"
                ? "btnSearchField_tag btnSearchField_tag_active"
                : "btnSearchField_tag"
            }
          >
            Culture
          </div>
          <div
            onClick={() => {
              SelectmagazineType("opinion");
            }}
            className={
              magazineType === "opinion"
                ? "btnSearchField_tag btnSearchField_tag_active"
                : "btnSearchField_tag"
            }
          >
            Opinion
          </div>
          <div
            onClick={() => {
              SelectmagazineType("grunion");
            }}
            className={
              magazineType === "grunion"
                ? "btnSearchField_tag btnSearchField_tag_active"
                : "btnSearchField_tag"
            }
          >
            Grunion
          </div>
          <div
            onClick={() => {
              SelectmagazineType("studentlife");
            }}
            className={
              magazineType === "studentlife"
                ? "btnSearchField_tag btnSearchField_tag_active"
                : "btnSearchField_tag"
            }
          >
            Student Life
          </div>
        </div>

        <div className="megazineSection">
          {Magazinedata.map((data) => {
            if (
              data.magazine_name
                .toLowerCase()
                .includes(magazineSearch.toLowerCase()) &&
              (magazineType === "all"
                ? true
                : magazineType === data.magazine_type)
            ) {
              return (


                <NavLink to={"/magazineSearch/"+data.magazine_id}  className="navLinkDesign">
                  <div className="cardSectionMG">
                    <img src={data.magazine_img} alt="asd" />
                    <div className="card-content">
                      <div className="headTitle">{data.magazine_name} </div>
                      <div className="textDataCard">{data.magazine_titleData}</div>
                    </div>
                  </div>
                </NavLink>

              );
            }
          })}
        </div>
      </div>
    </>
  );
};

export default MagazineAll;
